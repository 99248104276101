import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const PoliciesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  max-width: ${props => props.theme.containerWidths.large};
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 80px;
  }
`

const Title = styled.h3`
  margin-bottom: 5px;
  text-align: left;
`

const Support = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPolicy(sort: { fields: [order], order: [ASC] }) {
        edges {
          node {
            createdAt
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            allowed
          }
        }
      }
    }
  `)

  const policies = data.allContentfulPolicy.edges

  return (
    <>
      <PoliciesList>
        {policies.map(({ node: policy }) => (
          <li key={policy.id}>
            <Title className="section-title">
              {policy.allowed ? 'Do' : `Don't`}
            </Title>
            <div
              dangerouslySetInnerHTML={{
                __html: policy.body.childMarkdownRemark.html,
              }}
            />
          </li>
        ))}
      </PoliciesList>
    </>
  )
}

export default Support
